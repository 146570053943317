import mapVerify from '@/utils/formValidate'
import ruleVerify from '@/libs_sz/utils/formValidateV2'

// 获取{{ $t('lang.gles.common.moreQuery') }}字段列表
export const getMoreQueryFormData = (that) => {
  const moreQueryData = [
    // 工厂编码
    {
      name: 'factoryCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryCode')
    },
    // 工厂名称
    {
      name: 'factoryName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryName')
    },
    // 外部编码
    {
      name: 'factoryHostCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.hostCode')
    }
  ]
  moreQueryData.forEach(item => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 工厂编码
    {
      prop: 'factoryCode',
      label: that.$t('lang.gles.base.factoryCode'),
      isShow: true,
      showOverflowTooltip: true,
      sortable: true
    },
    // 工厂名称
    {
      prop: 'factoryName',
      label: that.$t('lang.gles.base.factoryName'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 外部编码
    {
      prop: 'factoryHostCode',
      label: that.$t('lang.gles.base.hostCode'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 工厂描述
    {
      prop: 'factoryDescription',
      label: `${that.$t('lang.gles.base.factoryDescription')}`,
      isShow: true,
      showOverflowTooltip: true
    }
  ]
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 工厂编码
    {
      name: 'factoryCode',
      value: row.factoryCode || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryCode'),
      rules: mapVerify(['required', 'inputCode']),
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 工厂名称
    {
      name: 'factoryName',
      value: row.factoryName || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryName'),
      placeholder: '200个字符以内，支持任意字符',
      rules: mapVerify(['required', 'inputLen200'])
    },
    // 工厂外部编码
    {
      name: 'factoryHostCode',
      value: row.factoryHostCode || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.hostCode'),
      placeholder: '外部系统的工厂编码，50个字符以内',
      rules: mapVerify(['inputLen50'])
    },
    // 工厂描述
    {
      name: 'factoryDescription',
      value: row.factoryDescription || '',
      span: 24,
      component: 'elInput',
      type: 'textarea',
      rows: 6,
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.factoryDescription'),
      rules: ruleVerify(['inputLen255'])
    }
  ]
  formData.forEach(item => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}
