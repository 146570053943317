<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
      />
    </el-card>
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import listMixins from '@/mixins/listMixins'
import QueryView from '@/components/moreQuery/queryView.vue'

export default {
  name: 'FactoryList',
  components: {
    ColumnConfig,
    MTable,
    QueryView
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        codeOrName: ''
      },
      formModel: {}
    }
  },
  computed: {
    moreQueryData() {
      return getMoreQueryFormData(this, { ...this.formModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      return getSearchTableItem(this, {}, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter((item, i) => item.name === this.columnConfigList[i]?.name && this.columnConfigList[i]?.isShow)
      }
      return list
    }
  },
  async created() {
    this.doSearch(this.formModel)
  },
  methods: {
    // 查询
    async doSearch(params, api = 'getFactoryList') {
      const { data, code } = await this.$httpService(this.$apiStore.base(api, this.pageData), params)
      if (code) return
      const { recordList = [], currentPage, pageSize, totalRecordCount: recordCount } = data || {}
      this.pageData = { currentPage, pageSize, recordCount }
      this.tableData = recordList || []
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getFactoryList')
    },
    // 新增
    updateCom() {
      this.$emit('updateCom', {
        currentCom: 'FactoryAdd',
        mode: 'add'
      })
    },
    /**
     * 编辑
     */
    editItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'FactoryAdd',
        mode: 'edit',
        row
      })
    },
    /**
     * 删除
     */
    async delItem({ row }) {
      const { code } = await this.$httpService(this.$apiStore.base('deleteFactory'), { id: row.id, factoryCode: row.factoryCode })
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData?.length < 2) {
        this.pageData.currentPage = 1
      }
      this.doSearch(this.baseFormModel)
    }
  }
}
</script>
