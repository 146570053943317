<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
        :button="button"
        class="basic-data-add-form"
        @submit="onSubmit"
        @reset="onCancel"
      >
        <template #inputCodeTipIcon>
          <el-tooltip popper-class="workshop_code_tip-icon" effect="dark" :content="$t(autoGeneCodeTip)" placement="right">
            <i class="el-icon-tip el-icon-question" />
          </el-tooltip>
        </template>
      </m-form>
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import { getEditBaseFormData } from '../data'
import addMixins from '@/mixins/addMixins'

export default {
  name: 'FactoryAdd',
  components: {
    MForm,
    HeaderPath
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {
    headerPaths() {
      return this.mode === 'add' ? [this.$t('lang.gles.base.factoryAdd')] : [this.$t('lang.gles.base.factoryEdit')]
    },
    baseFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row })
    }
  },
  created() {
    if (this.mode === 'add') {
      this.getSerialBizCode('factorySerialNum', 'factoryCode')
    }
  },
  methods: {
    onSubmit() {
      // 先校验
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        const params = this._.cloneDeep(this.formModel)
        this.mode === 'add' ? await this.insert(params) : await this.update(params)
      })
    },
    async insert(params) {
      const { code } = await this.$httpService(this.$apiStore.base('insertFactory'), params)
      if (code) return
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
    },
    async update(params) {
      const { code } = await this.$httpService(this.$apiStore.base('updateFactory'), params)
      if (code) return
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
    },
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'FactoryList',
        mode: ''
      })
    }
  }
}
</script>
